import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const sit: IPartnerAppSettings = {
  cdnBaseUrl: '',
  serviceEndPoints: [
    {
      serviceEndPoint: "https://o2cpmssit-project-api-0.azurewebsites.net/api/",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    }
  ],
  UmAppConfiguration: {
    projectServiceBaseUri:
      "https://o2cpmssit-project-api-0.azurewebsites.net/api/",
    projectServiceSubscriptionKey: "dc42c2d47e5d4162aa39d4114015717d",    
    fluentUiCdn:
      "https://static2.sharepointonline.com/files/fabric/assets/icons/",
    fxpFlightingUrl:
      "https://flighting-api-preprod.azurefd.net/api/v1/evaluate?featureNames=QuotesAccess,OBOReadAccess,OBOReadWriteAccess,RenewalAccess",
    oneProfileBaseUri: "https://oneprofilesitapipack-aurora.azurewebsites.net",
    isBulkApprovalDisabled: false
  }
};
