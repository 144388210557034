import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const uat: IPartnerAppSettings = {
  cdnBaseUrl: 'https://umuatui.azureedge.net/um-react',
  serviceEndPoints: [
    {
      serviceEndPoint: "https://projectservices-api-uat.z01.azurefd.net/api/",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    },
    {
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4",
      serviceEndPoint: "https://projectservices-api-uat.z01.azurefd.net/api/"
    }
  ],
  UmAppConfiguration: {
    projectServiceBaseUri:
      "https://projectservices-api-uat.z01.azurefd.net/api/",
    projectServiceFunctionV3BaseUrl: "https://projectservices-api-uat.z01.azurefd.net/api",
    projectServiceSubscriptionKey: "dc42c2d47e5d4162aa39d4114015717d",
    utilityApiPath: "dmutility/api",
    fluentUiCdn:
      "https://static2.sharepointonline.com/files/fabric/assets/icons/",
    fxpFlightingUrl:
      "https://flighting-api-preprod.azurefd.net/api/v1/evaluate?featureNames=QuotesAccess,OBOReadAccess,OBOReadWriteAccess,RenewalAccess",
    oneProfileBaseUri: "https://oneprofileuatapipack.trafficmanager.net",
    isBulkApprovalDisabled: false
  }
};
